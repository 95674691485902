import "../styles/index.scss";
import M from "materialize-css/dist/js/materialize.min";

window.document.addEventListener("DOMContentLoaded", function () {
  M.AutoInit();
});

document.addEventListener("DOMContentLoaded", function () {
  var elems = document.querySelectorAll(".datepicker");
  const options = {
    format: "dd/mm/yyyy",
    yearRange: 100,
  };
  M.Datepicker.init(elems, options);
});

window.document.showLoadingSpinner = () => {
  const dashboardContent = document.getElementById("dashboard-content");
  const spinnerContainer = document.getElementById("spinner-container");
  if (dashboardContent !== null) {
    dashboardContent.style.display = "none";
  }
  if (spinnerContainer !== null) {
    spinnerContainer.style.display = "block";
  }
};

window.downloadChart = (id, data) => {
  const cleanData = JSON.parse(data);
  const imageLink = document.createElement("a");
  const canvas = document.getElementById(id);
  console.log(canvas);
  imageLink.download = `${cleanData.title}.png`;
  imageLink.href = canvas.toDataURL("image/png", 1);
  imageLink.click();
};
